<template>
    <div class="deck-page">

        <div class="mode-selector" v-if="showModelSelector">
            <transition name="fadeIn">
                <div class="mode-selector-bg" v-if="showModelSelectorContent"></div>
            </transition>
            <transition name="slideInDown">
                <div class="mode-selector-content" v-if="showModelSelectorContent">
                    <div class="mode-selector-title">请选择拼图模式</div>
                    <div class="mode-selector-img">
                        <img src="/assets/images/scheme-model-select.png" alt="">
                    </div>
                    <div class="mode-btn" @click="modelSelected('professional')">专业拼图模式</div>
                    <div class="mode-btn" @click="modelSelected('simple')">快速简易模式</div>
                </div>
            </transition>
        </div>
        <div class="mode-selector" v-if="showThumbSelector">
            <transition name="fadeIn">
                <div class="mode-selector-bg" v-if="showThumbSelectorContent"></div>
            </transition>
            <transition name="slideInDown">
                <div class="thumb-selector-content" v-if="showThumbSelectorContent">
                    <div class="label">选择展示商品主图</div>
                    <div class="line"></div>
                    <div class="thumbs">
                        <div class="thumb" :class="{ active: img == thumbCurrent }"
                            :style="`background-image: url(${img})`" v-for="(img, idx) in thumbList" :key="img"
                            @click="thumbSelectHandler(img)">
                            <div class="count">{{ idx + 1 }}</div>
                            <div class="mask" v-if="img == thumbCurrent">
                                <img src="/assets/images/deck/deck-select-thumb.png">
                            </div>
                        </div>
                    </div>
                    <div class="close" @click="closeThumbSelector">
                        <img src="/assets/images/deck/deck-select-thumb-close.png">
                    </div>
                </div>
            </transition>
        </div>
        <div class="mode-selector" v-if="showkouSelector">
            <transition name="fadeIn">
                <div class="mode-selector-bg" v-if="showkouSelectorContent"></div>
            </transition>
            <transition name="slideInDown">
                <div class="thumb-selector-content kou-selector-content" v-if="showkouSelectorContent">
                    <div class="label">提示</div>
                    <div class="line"></div>
                    <div class="tips">
                        <div style="font-weight: bold;">请在下方选择一张图片</div>
                        <div>抠后若效果不佳可切换图后重试抠图</div>
                    </div>
                    <div class="white-img-box">
                        <div class="white-img" :style="kouBackground">
                            <div class="thumb" :style="`background-image: url(${whiteImage || kouImage})`"></div>
                            
                            <div class="kou" v-if="kouStatus == 1" @click="kouFun">
                                <img src="/assets/images/deck/kou-icon.png">
                                <span>抠图</span>
                            </div>
                            
                            <div class="kou-loading" v-if="kouStatus == 2">
                                <a-spin>
                                    <a-icon slot="indicator" type="loading-3-quarters" style="color:#fff;font-size: 14px" spin />
                                </a-spin>
                                <span>正在抠图...</span>
                            </div>
                            
                            <div class="kou-btn" v-if="kouStatus == 3">
                                <div class="reset" @click="kouReset">撤销</div>
                                <div class="confirm" @click="kouConfirm">确定</div>
                            </div>
                            
                            <div class="kou-loading" v-if="kouStatus == 4">
                                <a-spin>
                                    <a-icon slot="indicator" type="loading-3-quarters" style="color:#fff;font-size: 14px" spin />
                                </a-spin>
                                <span>正在保存...</span>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-box">
                        <swiper class="swiper" ref="mySwiper" :options="swiperOption">
                            <swiper-slide class="slide" :class="{slideAc:url == kouImage}" v-for="(url) in thumbList" :key="url">
                                <img class="swiper-img" :src="url">
                            </swiper-slide>
                            <div class="btn swiper-button-prev" slot="button-prev" @click="prevSwiper">
                                <img src="/assets/images/scheme/info-icon-left.png">
                            </div>
                            <div class="btn swiper-button-next" slot="button-next" @click="nextSwiper">
                                <img src="/assets/images/scheme/info-icon-right.png">
                            </div>
                        </swiper>
                    </div>
                    <div class="close" @click="closeKouSelector">
                        <img src="/assets/images/deck/deck-select-thumb-close.png">
                    </div>
                </div>
            </transition>
        </div>
        <div class="page-left">
            <div class="deck-area-box">
                <div class="deck-area-head">
                    <div class="copy-box">
                        <img src="/assets/images/deck/icon1.png" @click="imageDeck.preview()">
                        <div class="upload-simple" v-if="schemeModel == 'simple'">
                            <a-upload name="image" :before-upload="beforeUpload" :show-upload-list="false"
                                :action="fullAction" @change="handleChange">
                                <span>上传搭配效果图</span>
                            </a-upload>
                        </div>
                        <img src="/assets/images/deck/icon2.png" @click="imageDeck.showTemplate()" v-else>
                    </div>
                    <div class="flex">
                        <!-- <div v-if="schemeModel !== 'simple'" class="auto-btn" @click="autoScheme()">
                            <img src="/assets/images/deck/icon3.png">
                            <span>自动拼图</span>
                        </div> -->
                        <div class="mode-switch-btn" @click="activeModeSelector"></div>
                    </div>
                </div>
                <div class="deck-area" :class="schemeModel">


                    <template v-if="schemeModel === 'simple'">

                        <image-deck-simple :key="simleKey" :mode="schemeModel" @deleted="deckImageDeleted"
                            :goodsList="selectedGoods" :images="deckImages" ref="imageDeckSimple" />

                    </template>
                    <template v-else>

                        <image-deck :mode="schemeModel" @deleted="deckImageDeleted" :goodsList="selectedGoods"
                            :images="deckImages" @switchImg="switchImg" ref="imageDeck" />
                    </template>

                </div>
                <div class="deck-area-tool"></div>
            </div>
        </div>
        <div class="page-right">
            <div class="goods-select">
                <div class="item" @click="goodsShopActive = true">
                    <img src="/assets/images/deck/icon6.png">
                    <span>自营店铺</span>
                </div>
                <div class="item" @click="goodsStockActive = true">
                    <img src="/assets/images/deck/icon4.png">
                    <span>商品库</span>
                </div>
                <div class="item" @click="goodsCollectActive = true">
                    <img src="/assets/images/deck/icon5.png">
                    <span>收藏夹</span>
                </div>
            </div>

            <template v-if="loadingOutfit">
                <img width="48" src="/assets/images/loading.gif">
            </template>
            <template v-else-if="selectedGoods.length === 0">
                <goods-empty></goods-empty>
            </template>
            <template v-else>
                <perfect-scrollbar :options="{ suppressScrollY: false }" class="goods-scroll" :class="schemeModel">
                    <div class="goods-list">

                        <div @click="top(goods)" class="goods-block" v-for="(goods, key) in selectedGoods"
                            :key="goods.id"
                            :class="{ 'used': usedGoods.indexOf(goods) >= 0, 'third': (key + 1) % 3 === 0 }">

                            <button @click.stop="deleteGoods(goods)" class="del-btn"><span
                                    class="iconfont pc-guanbi"></span></button>

                            <goods-item disable :goods="goods" :schemeModel="schemeModel" @selectThumb="selectThumb" @selectKou="selectKou" />
                        </div>

                        <div></div>
                        <div></div>
                        <div></div>

                    </div>
                </perfect-scrollbar>

                <div class="next">
                    <button @click="next" class="btn">下一步</button>
                </div>
            </template>


        </div>

        <drawer width="720px" v-model="goodsStockActive">
            <goods-stock :selected-list="selectedGoods" type="goods" @selected="goodsSelected" />
        </drawer>

        <drawer width="720px" v-model="goodsCollectActive">
            <goods-stock :selected-list="selectedGoods" type="collect" @selected="goodsSelected" />
        </drawer>

        <drawer width="720px" v-model="goodsShopActive">
            <goods-stock :selected-list="selectedGoods" type="shop" @selected="goodsSelected" />
        </drawer>
    </div>
</template>

<script>
import api from "@/repo/api";
import auth from "@/auth";
import Drawer from "@/components/Drawer";
import GoodsItem from "@/components/GoodsItem";
import GoodsStock from "./Com/GoodsStock.vue";
import ImageDeck from "./Com/ImageDeck.vue";
import GoodsEmpty from "@/views/AST/Shop/Com/GoodsEmpty.vue";
import ImageDeckSimple from "@/views/AST/Shop/Com/ImageDeckSimple.vue";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
    
    components: { Swiper, SwiperSlide, ImageDeckSimple, GoodsEmpty, GoodsStock, Drawer, GoodsItem, ImageDeck },
    data() {
        const _this = this;
        return {
            // schemeModel: "professional",
            // schemeModel: "simple",
            kouStatus: 1,
            schemeModel: null,
            hideMenu: true,
            showSchemeModal: true,
            showModelSelector: false,
            showThumbSelector: false,
            showkouSelector: false,
            showModelSelectorContent: false,
            showThumbSelectorContent: false,
            showkouSelectorContent: false,
            selectedGoods: [],
            usedGoods: [],
            deckImages: [],
            drawerActive: false,
            goodsStockActive: false,
            goodsShopActive: false,
            goodsCollectActive: false,
            goodsCustomActive: false,
            outfit: null,
            goods: [],
            loadingOutfit: false,
            totalPrice: '0.00',
            totalComm: '0.00',
            appId: auth.appId(),
            detailId: 0,
            schemeType: 'bang',
            showDetailQrcode: false,
            shopGoodsTypes: [
                'wechat',
                'yc-shop',
                'youzan'
            ],
            thumbList: [],
            thumbCurrent: 0,
            goodsCurrent: null,
            simleKey: 9898521,
            swiperOption: {
                slidesPerView: 'auto',
                spaceBetween: 8,
                centeredSlides: true,
                slideToClickedSlide: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                on: {
                    slideChange() {
                        _this.kouImage = _this.thumbList[this.activeIndex];
                    }
                }
            },
            kouItem:{},
            kouImage:'',
            whiteImage:''
        }
    },
    watch: {
        '$route.query'() {
            this.showDetailQrcode = false
            this.getOutfit()
        },
        usedGoods() {
            let prices = 0;
            let comm = 0

            this.usedGoods.forEach(item => {

                prices += parseFloat(item.lowest_price || (item.min_price / 100).toFixed(2))
                comm += parseFloat(item.commission || 0.00)

            })

            this.totalPrice = prices.toFixed(2)
            this.totalComm = comm.toFixed(2)
        },
    },
    mounted() {
        this.getOutfit()
        this.getGoods()
    },
    computed: {
        kouBackground() {
            return {
                backgroundImage: `url(/assets/images/deck/msk.png)`
            }
        },
        fullAction() {
            let appId = localStorage['mini_app_id'];
            return api.colUrl('/image/upload') + '?token=' + auth.getToken() + '&_mini_app_id=' + appId + '&usage=deck'
        },
        imageDeck() {
            if (this.schemeModel == "simple") {
                return this.$refs['imageDeckSimple']
            } else {
                return this.$refs['imageDeck']
            }
        }
    },
    methods: {
        kouReset(){
            this.kouStatus = 1;
            this.whiteImage = '';
        },
        kouConfirm(){
            this.kouStatus = 4;
            api.post('/ast-goods/upload-white-image', { id:this.kouItem.id,image:this.whiteImage }, (data) => {

                if (data.code === 0) {
                    this.imageDeck.switchWhiteImg(this.kouItem.id, this.whiteImage);
                    
                    this.usedGoods.forEach((item) => {
                        if (item.id == this.kouItem.id) {
                            this.$set(item, 'white_image', this.whiteImage);
                        } 
                    })

                    this.selectedGoods.forEach(item => {
                        if (item.id == this.kouItem.id) {
                            this.$set(item, 'white_image', this.whiteImage);
                            this.closeKouSelector();
                        }
                    });
                } else {
                    this.kouStatus = 3;
                    this.$message.error('保存失败!');
                }

            });
        },
        switchImg(params){
            console.log('switchImg',params);
            
            let {whiteImg,originImg} = params;

            this.deckImages.forEach((item,idx) => {
                if (item == originImg) {
                    this.deckImages.splice(idx,1,whiteImg);
                }
            });
        },
        kouFun(){
            this.kouStatus = 2;
            api.post('/ast-shop/clothes-segment', { image:this.kouImage }, (data) => {

                if (data.code === 0) {
                    this.kouStatus = 3;
                    this.whiteImage = data.data.url;
                } else {
                    this.$message.error('抠图失败!');
                }

            });
        },
        prevSwiper() {
            this.$refs.mySwiper.$swiper.slidePrev();
        },
        nextSwiper() {
            this.$refs.mySwiper.$swiper.slideNext();
        },
        beforeUpload(file) {
            console.log('file', file);
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('你只能上传jpg,png文件!');
            }
            const isLt1M = file.size / 1024 / 1024 < 2;
            if (!isLt1M) {
                this.$message.error('图片必须小于2M!');
            }
            return isJpgOrPng && isLt1M;
        },
        handleChange(info) {
            if (info.file.status === 'uploading') {

            }
            if (info.file.status === 'done') {

                let response = info.file.response;

                let url = ''

                if (typeof this.done === 'function') {
                    url = this.done(response.data)
                } else {
                    url = response.data;
                    this.imageDeck.setThumb(url);
                }

                this.loading = false;

            }
        },
        selectKou(goods){
            this.$swal.fire({
                title: '请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })
            let id = 0;
            if (typeof goods.id == 'number') {
                id = goods.id;
            }
            if (typeof goods.id == 'string' && goods.id.indexOf('-') > 0) {
                id = goods.id.split('-')[0];
            }
            api.get('/ast-shop/goods-segment-image-list', { id }, (data) => {

                if (data.code === 0) {
                    this.kouItem = goods;
                    this.kouImage = data.data[0];
                    this.thumbList = data.data;
                    this.showkouSelector = true;
                    this.$nextTick(() => {
                        this.showkouSelectorContent = true;
                    })
                }

                this.$swal.close()

            });
        },
        thumbSelectHandler(img) {
            this.$swal.fire({
                title: '请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })
            this.thumbCurrent = img;
            this.selectedGoods.forEach(item => {
                if (item.id == this.goodsCurrent.id) {
                    this.imageDeck.findGoods((item.white_image || item.thumb || item.thumb_image), img);
                    this.$set(item, 'white_image', img);
                }
            });
            this.closeThumbSelector();
            this.$swal.close()
        },
        selectThumb(goods) {
            this.$swal.fire({
                title: '请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })
            let id = 0;
            if (typeof goods.id == 'number') {
                id = goods.id;
            }
            if (typeof goods.id == 'string' && goods.id.indexOf('-') > 0) {
                id = goods.id.split('-')[0];
            }
            api.get('/ast-shop/goods-segment-image-list', { id }, (data) => {

                if (data.code === 0) {
                    this.goodsCurrent = goods;
                    this.thumbList = data.data;
                    this.showThumbSelector = true;
                    this.$nextTick(() => {
                        this.showThumbSelectorContent = true;
                    })
                }

                this.$swal.close()

            });
        },
        closeThumbSelector() {
            this.showThumbSelectorContent = false;
            setTimeout(() => {
                this.showThumbSelector = false;
            }, 350)
        },
        closeKouSelector() {
            this.showkouSelectorContent = false;
            setTimeout(() => {
                this.kouReset();
                this.showkouSelector = false;
            })
        },
        autoScheme() {

            this.$swal.fire({
                title: '请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })

            let buildList = this.imageDeck.getFrames();
            let frameGoods = [];

            buildList.forEach((bitem) => {

                this.usedGoods.forEach(item => {

                    if (bitem.image !== item.white_image) return

                    let frame = {
                        w: parseInt(bitem.style.width),
                        h: parseInt(bitem.style.height),
                    }
                    if (item.yc_cate_id) {
                        frame.cate_id = item.yc_cate_id;
                    }

                    frameGoods.unshift(frame)
                })

            })

            api.post('/ast-scheme/auto-match-frame', { goods: frameGoods }, (data) => {

                this.$swal.close()

                if (data.code === 0 && data.data.length > 0) {
                    this.matchFrames(data.data[0])
                } else {
                    if (data.data.length == 0) {
                        // 没有可自动拼图的模式
                        this.$message.error('没有可自动拼图的模式');
                    }
                }

            });

        },
        cj(old, newer) {

            let arr = []

            if (!newer.length) return [].concat(old)

            if (!old.length) return [].concat(newer)

            newer.forEach(nitem => {

                let find = false

                old.forEach(oitem => {

                    if (oitem.id === nitem.id) find = true
                })

                if (!find) arr.push(nitem)
            })

            old.forEach(nitem => {

                let find = false

                newer.forEach(oitem => {

                    if (oitem.id === nitem.id) find = true
                })

                if (!find) arr.push(nitem)
            })


            return arr
        },
        deckImageDeleted(image) {
            console.log('deckImages',this.deckImages);
            
            let idx = this.deckImages.indexOf(image)
            if (idx < 0) return

            this.deckImages.splice(idx, 1)

            idx = -1;

            this.usedGoods.forEach((item, key) => {
                if (item.white_image !== image) return
                idx = key
            })

            if (idx < 0) return

            this.usedGoods.splice(idx, 1)
        },
        deckSave() {
            let data = {};

            this.$swal.fire({
                title: '请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })

            if (this.outfit) {
                data['id'] = this.outfit.id
                data['type'] = this.outfit.type
            }

            this.imageDeck.build((img, buildList) => {

                console.log('buildList', buildList);

                // data['image'] = img

                localStorage.setItem('image', img);

                let goods = []
                let astGoods = []
                let frames = {
                    "width": "600",
                    "height": "600",
                }
                let frameGoods = []

                buildList.forEach((bitem) => {
                    if (bitem.type == 'thumb') {
                        let frame = {
                            x: parseInt(bitem.style.left),
                            y: parseInt(bitem.style.top),
                            w: parseInt(bitem.style.width),
                            h: parseInt(bitem.style.height),
                            id: 0,
                            z: bitem.style.zIndex,
                            cs: bitem.contentStyle,
                            image: bitem.image,
                        }
                        frameGoods.unshift(frame)
                    } else {
                        this.usedGoods.forEach(item => {

                            if (
                                bitem.image !== item.white_image
                            ) return

                            goods.push(item.id)

                            let type = 'bang'
                            let iid = [];


                            if (typeof item.id == 'string') {
                                iid = item.id.split('-')
                            } else {
                                iid = [item.id, '']
                            }


                            if (this.shopGoodsTypes.indexOf(item.from) >= 0) {
                                type = 'shop'
                            }

                            astGoods.push({
                                id: iid[0],
                                type,
                                color: iid[1] || '',
                                from: item.from
                            })
                            let frame = {
                                x: parseInt(bitem.style.left),
                                y: parseInt(bitem.style.top),
                                w: parseInt(bitem.style.width),
                                h: parseInt(bitem.style.height),
                                id: item.id,
                                z: bitem.style.zIndex,
                                cs: bitem.contentStyle,
                                image: bitem.image,
                            }
                            if (item.yc_cate_id) {
                                frame.cate_id = item.yc_cate_id;
                            }
                            frameGoods.unshift(frame)
                        })
                    }

                })

                frames['mode'] = this.schemeModel
                frames['goods'] = frameGoods
                data['frame'] = frames
                data['type'] = 'shop'

                console.log('======================');
                console.log('frames', frames);
                console.log('astGoods', astGoods);
                console.log('data', data);
                console.log('usedGoods', this.usedGoods);


                if (this.appId) {

                    data['goods'] = astGoods;

                    this.$swal.close();

                    console.log(999, data);

                    this.$router.push('/ast/shop/scheme/info?data=' + JSON.stringify(data))

                }
            })
        },
        heihei() {

        },
        deleteGoods(item) {

            let idx = this.selectedGoods.indexOf(item)

            this.selectedGoods.splice(idx, 1)

            idx = this.usedGoods.indexOf(item)
            if (idx >= 0) this.usedGoods.splice(idx, 1)

            if (this.schemeModel == 'simple') {
                this.imageDeck.removeGoods(item);
            } else {
                this.reloadGoodsImage()
            }
        },
        getGoods() {
            let id = this.$route.query.goods

            if (!id) return;

            api.get('/goods/ids', { ids: id }, (data) => {

                if (data.code === 0 && data.data.data.length) {
                    this.selectedGoods = data.data.data
                    this.usedGoods = data.data.data
                    this.reloadGoodsImage()
                }

            });

        },
        modelSelected(mode) {

            this.schemeModel = mode

            localStorage.setItem('scheme_used_mode', mode)

            this.showModelSelectorContent = false

            if (this.usedGoods.length) {
                this.reloadGoodsImage()
            }

            setTimeout(() => {
                this.showModelSelector = false
            }, 350)
        },
        loadMode() {
            const mode = localStorage.getItem('scheme_used_mode')

            if (mode) {
                this.schemeModel = mode
            } else {
                this.activeModeSelector()
            }
        },
        activeModeSelector() {
            this.showModelSelector = true
            this.$nextTick(() => {
                this.showModelSelectorContent = true
            })
        },
        getOutfit() {

            let id = this.$route.query.outfit_id

            if (!id && !this.outfit) {
                this.loadMode()
                return
            }

            if (!id && this.outfit) {
                return location.reload()
            }
            if (id && this.outfit && this.outfit.id !== id) {
                return location.reload()
            }

            this.loadingOutfit = true

            api.get('/ast-scheme/scheme-detail', { id }, (data) => {

                this.loadingOutfit = false

                if (data.code !== 0) return

                data.data.goods.forEach(goods => {

                    if ((this.shopGoodsTypes.indexOf(goods.from) === -1) || (!goods.color)) return;

                    goods.title = goods.product_name + ' - ' + goods.color
                    goods.id = goods.id + '-' + goods.color

                })

                this.loadOutfit(data.data)
            })

        },

        goodsSelected(goods) {

            this.goodsCollectActive = false
            this.goodsStockActive = false
            this.goodsShopActive = false
            this.goodsCustomActive = false

            if (!goods || !goods.length) return

            // let unused = this.cj(this.selectedGoods, this.usedGoods)

            // this.usedGoods = this.cj(unused, goods)

            this.selectedGoods = goods

            // this.reloadGoodsImage()
        },

        loadOutfit(data) {

            this.outfit = data
            this.selectedGoods = data.goods
            this.usedGoods = data.goods

            if (data.frame && data.frame.frame) {

                let list = []

                let bl = 600 / data.frame.frame.width;

                this.schemeModel = data.frame.frame.mode || 'professional';

                let zh = (v) => {
                    return bl * v;
                }

                data.frame.frame.goods.forEach(item => {
                    let temp = null;
                    if (item.id == 0) {
                        temp = item;
                    } else {
                        let image = '';

                        this.usedGoods.forEach(goods => {
                            if (goods.id === item.id) image = goods.white_image
                        })

                        if (!image) return;

                        list.unshift({
                            type: 'image',
                            image: image,
                            style: {
                                left: zh(item.x) + 'px',
                                top: zh(item.y) + 'px',
                                width: zh(item.w) + 'px',
                                height: zh(item.h) + 'px',
                                zIndex: item.z
                            },
                            contentStyle: item.cs || {},
                            active: false,
                        })
                    }
                    if (temp) {

                        list.unshift({
                            type: 'thumb',
                            image: temp.image,
                            style: {
                                left: zh(item.x) + 'px',
                                top: zh(item.y) + 'px',
                                width: zh(item.w) + 'px',
                                height: zh(item.h) + 'px',
                                zIndex: item.z || 1
                            },
                            contentStyle: item.cs || {},
                            active: false,
                        })
                    }
                })

                this.$nextTick(() => {
                    this.imageDeck.setImageData(list)
                })

            } else {
                this.schemeModel = 'professional'

                this.reloadGoodsImage()
            }

        },
        matchFrames(frameData) {

            let frame = frameData.frame.goods;

            let newFrameList = []

            this.usedGoods.forEach(item => {

                for (const frameKey in frame) {

                    let subFrame = frame[frameKey];

                    if (item.yc_cate_id != subFrame.cate_id) continue;

                    frame.splice(frameKey, 1);

                    newFrameList.push({
                        image: item.white_image,
                        type: "image",
                        active: false,
                        style: {
                            width: `${subFrame.w}px`,
                            height: `${subFrame.h}px`,
                            left: `${subFrame.x}px`,
                            top: `${subFrame.y}px`,
                            zIndex: 80 + frameKey,
                        }
                    });

                    break;
                }
            })
            this.imageDeck.setFrames(newFrameList);
        },
        next() {

            if (!this.usedGoods.length) {

                this.$swal({
                    title: '请先完成拼图',
                    icon: 'error',
                    confirmButtonText: '好'
                });
                return
            }
            this.deckSave();
        },
        reloadGoodsImage() {

            let images = []

            this.usedGoods.forEach(item => {

                let image = item.white_image

                if (image) images.push(image)
            })

            this.deckImages = images
        },

        top(item) {

            if (this.usedGoods.indexOf(item) === -1) {

                this.usedGoods.push(item);

                this.reloadGoodsImage();
            }

            setTimeout(() => this.imageDeck.active(item.white_image), 50)
        }
    }
}
</script>

<style lang="less" scoped>
.deck-page {
    display: flex;
}

.page-left {
    margin-right: 20px;
}

.page-right {

    .next {
        margin-top: 32px;
    }

    .goods-scroll {
        height: 565px;
        margin-top: 24px;

        &.simple {
            height: 590px;
        }
    }

}

.deck-area-box {
    width: 644px;
    background: #F5F5F5;

    .deck-area {
        width: 600px;
        height: 600px;
        border: 1px dashed #000000;
        margin: 0 auto;

        &.simple {
            border: none;
            margin-top: 22px;
        }
    }
}

.deck-area-head {
    width: 644px;
    height: 48px;
    background: rgba(51, 51, 51, 0.04);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;

    .copy-box {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
            width: 32px;
            height: 32px;
            margin-right: 12px;
        }

        .upload-simple {
            width: 142px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            background: #FFFFFF;
            border-radius: 60px;
        }
    }

    .auto-btn {
        width: 100px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 74px;
        cursor: pointer;

        img {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }

        span {
            color: #333333;
            font-size: 14px;
        }
    }
}

.mode-switch-btn {
    width: 32px;
    height: 32px;
    background-image: url("/assets/icons/model-switch-btn.png");
    background-size: contain;
    cursor: pointer;
    margin-left: 24px;
}


.deck-area-tool {
    width: 644px;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 24px;

    .tool-item {
        height: 48px;
        display: flex;
        align-items: center;
        margin-right: 16px;

        img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }

        span {
            color: #333333;
            font-size: 14px;
        }
    }
}

.goods-select {

    display: flex;
    align-items: center;

    .item {
        width: 106px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(96deg, #5F5F5F 0%, #333333 100%);
        margin-right: 16px;
        cursor: pointer;

        img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
        }

        span {
            color: #FFFFFF;
            font-size: 14px;
        }
    }
}

.goods-list {
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
}

.goods-block {
    cursor: pointer;
    width: 200px;
    margin-bottom: 16px;
    position: relative;
    border: 1px solid #ffff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.06);

    &.third {
        margin-right: 0;
    }

    &:hover .del-btn {
        opacity: 1;
    }

    &.used {
        border-color: #333333;
    }

    .del-btn {
        width: 24px;
        height: 24px;
        background-color: white;
        border: 1px solid #EEEEEE;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 99;
        font-size: 12px;
        opacity: 0;

        span {
            font-size: 12px;
        }
    }

    /deep/ .goods-item {
        height: auto;
        overflow: hidden;

        .image-container {
            margin-bottom: 0;
        }
    }

    /deep/ .goods-detail {
        padding: 12px;
        line-height: 19px;

        .goods-name {
            margin-bottom: 8px;
        }

        .name {
            line-height: 16px;
            font-weight: 500;
        }

        .lowest {
            font-weight: 500;
            font-size: 16px;
            color: #E52553;
            line-height: 19px;
        }
    }
}

.mode-selector {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;

    .mode-selector-bg {
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .mode-selector-content {
        width: 320px;
        height: 330px;
        background: rgb(243, 243, 243);
        border-radius: 16px 16px 16px 16px;
        padding: 16px;
        position: relative;
        z-index: 1;
    }

    .thumb-selector-content {
        background: linear-gradient(180deg, #FFFFFF 0%, #F0FDFF 100%);
        border-radius: 8px;
        z-index: 1;
        padding: 24px;
        position: relative;

        .close {
            position: absolute;
            top: 20px;
            right: 20px;

            img {
                width: 16px;
                height: 16px;
            }
        }

        .label {
            font-weight: bold;
            font-size: 16px;
            color: #333;
        }

        .line {
            width: 344px;
            height: 1px;
            background: #EEEEEE;
            margin-top: 16px
        }

        .thumbs {
            width: 344px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 24px;

            .thumb {
                width: 80px;
                height: 80px;
                margin-right: 8px;
                margin-bottom: 8px;
                background-color: #F5F5F5;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;

                .count {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 3;
                    width: 20px;
                    height: 14px;
                    line-height: 14px;
                    text-align: center;
                    background: #333333;
                    color: #FFFFFF;
                    font-size: 10px;
                    font-weight: bold;
                }

                &:nth-child(4n) {
                    margin-right: 0;
                }
            }

            .active {
                .mask {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 2;
                    background: rgba(0, 0, 0, 0.4);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }

    .kou-selector-content {
        .line {
            width: 434px;
            margin: 16px auto 0;
        }

        .tips {
            color: #333333;
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            margin: 24px auto 0;
        }

        .white-img-box {
            width: 200px;
            height: 200px;
            margin: 16px auto 0;
            .white-img{
                width: 200px;
                height: 200px;
                background-size: 100%;
                position: relative;
                .thumb {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 2;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                .kou{
                    width: 68px;
                    height: 28px;
                    background: linear-gradient( 180deg, #05E4DA 0%, #05B9DD 100%);
                    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
                    border-radius: 4px;
                    position: absolute;
                    left: calc(50% - 34px);
                    bottom: -10px;
                    z-index: 9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    img{
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                    }
                    span{
                        color: #FFFFFF;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
                .kou-loading{
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,0.4);
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span{
                        color: #FFFFFF;
                        font-size: 14px;
                        margin-left: 4px;
                    }
                }
                .kou-btn{
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    z-index: 9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    div{
                        width: 68px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.1);
                        border-radius: 4px;
                        font-size: 14px;
                        font-weight: bold;
                        cursor: pointer;
                    }
                    .reset{
                        color: #333333;
                        background: #FFFFFF;
                        margin-right: 16px;
                    }
                    .confirm{
                        color: #FFFFFF;
                        background: linear-gradient( 180deg, #05E4DA 0%, #05B9DD 100%);
                    }
                }
            }
        }

        .swiper-box {
            width: 356px;
            height: 48px;
            margin: 26px auto 0;
            overflow: hidden;
            position: relative;

            .swiper {
                height: 48px;
                width: 100%;

                /deep/ .swiper-wrapper {
                    display: flex;
                    margin-left: -30%;
                    // justify-content: center;
                }

                .slide {
                    width: 48px;
                    height: 48px;
                    flex-shrink: 0;
                    border-radius: 8px;
                    background: #FFFFFF;
                    overflow: hidden;
                }
                .slideAc{
                    border: 2px solid #07BFDE;
                }
                .swiper-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .btn {
                    width: 20px;
                    height: 48px;
                    background: rgba(7,191,222,0.2);
                    backdrop-filter: blur(6px);
                    position: absolute;
                    top: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0;
                    padding: 0;
                    &::after {
                        display: none;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                .swiper-button-prev {
                    border-radius: 8px 0px 0px 8px;
                    left: 0;
                }

                .swiper-button-next {
                    border-radius: 0px 8px 8px 0px;
                    right: 0;
                }
            }
        }
    }

    .mode-selector-img {
        text-align: center;
        margin-top: 23px;
        user-select: none;
        -webkit-user-drag: none;

        img {
            width: 224px;
        }
    }

    .mode-selector-title {
        font-weight: 500;
        font-size: 16px;
        color: #141414;
        line-height: 19px;
        text-align: center;
    }

    .mode-btn {
        &:first-child {
            margin-top: 37px;
        }

        margin-top: 12px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 40px;
        background: rgba(20, 20, 20, 0.5);
        height: 40px;
        border-radius: 8px;
        cursor: pointer;
        transition: all .3s;

        &:hover {
            background: #141414;
        }
    }
}
</style>