<template>
    <div class="deck">
        
        <div v-if="deckTpl" class="image-template" :style="`background-image: url(${deckTpl})`"></div>
        
        <div ref="imageWrapper" class="container preview">
            <div class="image-box" :class="{'simple-card': mode === 'simple' }" v-for="(item, k) in list" v-bind:key="k+3"
                 :style="setZIndex(item.style,k)">
                <div class="mask">
                    <template v-if="item.type === 'image'">
                        <img :style="item.contentStyle" :src="item.displayImage" alt="">
                    </template>
                    <template v-else-if="item.type === 'text'">
                        <div contenteditable="true">{{ item.text }}</div>
                    </template>
                </div>
            </div>
        </div>
        
        <div class="container">
            <div class="move-box" v-for="(image, k) in list" v-bind:key="k" @mousedown="move($event, image)"
                 :style="setZIndex(image.style, k)" :class="{ 'has-border': image === currentImage }">
                <div @mousedown.stop="scale($event, image)" class="img-scale"></div>
                <div @mousedown.stop="deleteImage(image)" class="img-delete">
                    <span class="iconfont pc-guanbi"></span>
                </div>
            </div>
        </div>
        
        <div class="clearfix"></div>
        
        <div class="deck-tools">
            <div class="tool-item" @click="top(currentImage)">
                <img src="/assets/images/deck/tool-icon1.png">
                <span>置顶</span>
            </div>
            <div class="tool-item" @click="bottom(currentImage)">
                <img src="/assets/images/deck/tool-icon2.png">
                <span>置底</span>
            </div>
            <div class="tool-item" @click="up(currentImage)">
                <img src="/assets/images/deck/tool-icon3.png">
                <span>上移</span>
            </div>
            <div class="tool-item" @click="down(currentImage)">
                <img src="/assets/images/deck/tool-icon4.png">
                <span>下移</span>
            </div>
            <div class="tool-item" @click="horizontalFlip(currentImage)">
                <img src="/assets/images/deck/tool-icon7.png">
                <span>垂直翻转</span>
            </div>
            <div class="tool-item" style="margin: 0;" @click="verticalFlip(currentImage)">
                <img src="/assets/images/deck/tool-icon8.png">
                <span>水平翻转</span>
            </div>
        </div>
        
        
        <div v-if="showPreviewModal" class="preview-modal full-screen">
            <div @click="showPreviewModal = false" class="preview-bg full-screen fadeIn"></div>
            <div class="preview-img fadeInDownBig">
                <button title="关闭" @click="showPreviewModal = false" class="preview-btn iconfont pc-guanbi"></button>
                
                <template v-if="tplLoading">
                    <p>搭配图片生成中，请稍候~</p>
                    <loader/>
                </template>
                <template v-else>
                    <img :src="previewImage" alt="">
                </template>
            
            </div>
        </div>
        
        <drawer v-model="templateContainerActive">
            <deck-template mode="professional" @selected="deckTplSelected"/>
        </drawer>
    
    </div>
</template>

<script>
import html2canvas from "html2canvas"
import Vue from 'vue';
import Loader from "@/components/Loader";
import Drawer from "@/components/Drawer";
import DeckTemplate from "./DeckTemplate";
import {GoodsElement} from './GoodsElement';

export default {
    name: "image-deck",
    components: {DeckTemplate, Drawer, Loader},
    props: {
        images: Array,
        mode: String,
        goodsList: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            previewImage: null,
            showPreviewModal: false,
            list: [],
            html2canvasOptions: {
                backgroundColor: null,
                proxy: 'https://api.ycsd.work/api/collocation/image/proxy',
            },
            templateContainerActive: false,
            currentImage: null,
            tplLoading: false,
            keyUpFunction: null,
            deckTpl: null,
        }
    },
    watch: {
        images() {
            this.reloadList()
        }
    },
    mounted() {
        window.mounted = true;
        
        this.reloadList()
        
        this.keyUpFunction = (event) => {
            this.keyUp(event.key)
        }
        
        document.addEventListener('keyup', this.keyUpFunction);
    },
    destroyed() {
        document.removeEventListener('keyup', this.keyUpFunction);
    },
    methods: {
        switchWhiteImg(id,whiteImg) {
            let originImg = '';
            this.goodsList.forEach((item) => {
                if (item.id == id) {
                    originImg = item.white_image;
                    this.$set(item,'white_image',whiteImg);
                } 
            })
            this.list.forEach( async(item,idx) => {
                if (item.originUrl == originImg || item._originUrl == originImg) {
                    
                    let style = {...item.style};
                    
                    let obj = null;
                    obj = await this.createImageItem(whiteImg);
                    
                    if (obj) {
                        obj.style.top = style.top;
                        obj.style.left = style.left;
                        obj.contentStyle = item.contentStyle || {};
                        this.list.splice(idx,1,obj);
                        this.$emit('switchImg',{whiteImg: whiteImg, originImg: item.originUrl});
                    }
                } 
            })
        },
        deckTplSelected(tpl) {
            
            this.templateContainerActive = false
            
            this.deckTpl = tpl.template
        },
        keyUp(keyName) {
            let img = this.currentImage
            
            if (!img) return
            
            let style = img.style
            
            switch (keyName) {
                case 'ArrowUp':
                    style.top = (parseInt(style.top) - 1) + 'px'
                    break;
                case 'ArrowDown':
                    style.top = (parseInt(style.top) + 1) + 'px'
                    break;
                case 'ArrowLeft':
                    style.left = (parseInt(style.left) - 1) + 'px'
                    break;
                case 'ArrowRight':
                    style.left = (parseInt(style.left) + 1) + 'px'
                    break;
                default:
                    return;
            }
            
            Vue.set(this.currentImage, 'style', style)
        },
        reloadList() {
            if (!this.images) return
            if (!this.images.length) {
                this.list = []
                return;
            }
            
            this.images.map(async (item) => {
                
                let inbox = false
                let del = []
                
                this.list.forEach((datum, dk) => {
                    if (datum.image === item) inbox = true
                    
                    if (this.images.indexOf(datum.image) === -1) {
                        del.push(dk)
                    }
                })
                
                del.forEach(dk => {
                    this.list.splice(dk, 1)
                })
                
                const className = Number.parseInt(this.getGoods(item)?.yc_cate_id)
                if (!inbox) this.list.push(await this.createImageItem(item, className))
            })
            
        },
        getGoods(image) {
            return this.goodsList.find(goods => goods.white_image === image)
        },
        horizontalFlip(img) {
            if (!img) return
            
            if (img.rotate === 1) {
                
                img.rotate = 0;
                
            } else if (img.rotate === 2) {
                
                img.rotate = 3
                
            } else if (img.rotate === 3) {
                
                img.rotate = 2
                
            } else {
                
                img.rotate = 1
                
            }
            
            this.addRotateStyle(img)
        },
        verticalFlip(img) {
            if (!img) return
            
            if (img.rotate === 2) {
                
                img.rotate = 0;
                
            } else if (img.rotate === 1) {
                
                img.rotate = 3;
                
            } else if (img.rotate === 3) {
                
                img.rotate = 1
                
            } else {
                img.rotate = 2
            }
            
            this.addRotateStyle(img)
        },
        addRotateStyle(img) {
            
            let style = img['contentStyle'] || {}
            
            if (!img.rotate) {
                
                delete style['transform']
                
            } else if (img.rotate === 1) {
                
                style['transform'] = 'rotateX(180deg)';
                
            } else if (img.rotate === 2) {
                
                style['transform'] = 'rotateY(180deg)';
                
            } else if (img.rotate === 3) {
                
                style['transform'] = 'rotateZ(180deg)';
                
            }
            
            Vue.set(img, 'contentStyle', style)
            
            this.$forceUpdate()
        },
        deleteImage(img) {
            let index = this.list.indexOf(img);
            
            this.currentImage = null;
            
            if (index === -1) return
            
            this.list.splice(index, 1)
            
            console.log('img', img);
            
            this.$emit('deleted', img.image)
        },
        scale(e, img) {
            this.top(img)
            this.currentImage = img;
            
            let initX = e.clientX
            // let initY = e.clientY
            //算出鼠标相对元素的位置
            let initWidth = e.target.parentNode.offsetWidth;
            let initHeight = e.target.parentNode.offsetHeight;
            const ratio = initWidth / initHeight
            document.onmousemove = (e) => {       //鼠标按下并移动的事件
                const deltaX = e.clientX - initX;
                let w = initWidth + deltaX
                let h = w / ratio
                img.style.width = w + 'px'
                img.style.height = h + 'px'
            };
            
            document.onmouseup = () => {
                document.onmousemove = null;
                document.onmouseup = null;
                img.active = false
            };
        },
        move(e, img) {
            
            let odiv = e.target;     //获取目标元素
            
            this.currentImage = img;
            this.top(img)
            
            img.active = true
            
            //算出鼠标相对元素的位置
            let disX = e.clientX - odiv.offsetLeft;
            let disY = e.clientY - odiv.offsetTop;
            
            document.onmousemove = (e) => {       //鼠标按下并移动的事件
                //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                let left = e.clientX - disX;
                let top = e.clientY - disY;
                
                //移动当前元素
                img.style.left = left + 'px';
                img.style.top = top + 'px';
            };
            
            document.onmouseup = () => {
                document.onmousemove = null;
                document.onmouseup = null;
                img.active = false
            };
        },
        preview() {
            
            this.showPreviewModal = true
            this.tplLoading = true
            
            setTimeout(() => {
                
                html2canvas(this.$refs.imageWrapper, this.html2canvasOptions).then(canvas => {
                    
                    this.$swal.close()
                    
                    this.previewImage = canvas.toDataURL("image/png");
                    
                    this.tplLoading = false
                    
                });
                
            }, 50)
        },
        showTemplate() {
            this.templateContainerActive = true;
        },
        build(callback) {
            
            let arr = []
            
            this.list.forEach(item => {
                if (item.type === 'image') arr.push(item)
            })
            
            
            html2canvas(this.$refs.imageWrapper, this.html2canvasOptions).then(canvas => {
                
                let img = canvas.toDataURL("image/png")
                
                callback(img, arr)
            });
        },
        active(image) {
            
            
            for (let i = 0; i < this.list.length; i++) {
                let item = this.list[i]
                
                if (item.image === image) {
                    this.currentImage = item
                    this.top(item)
                    return
                }
            }
            
        },
        top(item) {
            
            if (!item) return
            
            let idx = this.list.indexOf(item)
            if (idx <= 0) return
            this.list.splice(idx, 1)
            this.list.unshift(item)
            
        },
        bottom(item) {
            
            if (!item) return
            
            let idx = this.list.indexOf(item)
            if (idx < 0) return
            this.list.splice(idx, 1)
            
            this.list.push(item)
            
        },
        getFrames() {
            let arr = []
            
            this.list.forEach(item => {
                if (item.type === 'image') arr.push(item)
            })
            
            return arr
        },
        setFrames(frames) {
            this.list = frames
            this.$forceUpdate()
        },
        up(item) {
            if (!item) return
            
            let idx = this.list.indexOf(item)
            if (idx <= 0) return
            
            this.list.splice(idx, 1)
            this.list.splice(idx - 1, 0, item)
        },
        down(item) {
            if (!item) return
            
            let idx = this.list.indexOf(item)
            
            if (idx < 0 || (idx + 1) >= this.list.length) return
            
            this.list.splice(idx, 1)
            this.list.splice(idx + 1, 0, item)
        },
        setZIndex(style, index) {
            
            style.zIndex = 100 - index
            
            return style
        },
        getImageData() {
            return this.list;
        },
        setImageData(data) {
            
            data.forEach(async (item) => {
                
                let obj = null;
                
                obj = await this.createImageItem(item.image);
                
                if (obj) {
                    obj.style = item.style;
                    obj.contentStyle = item.contentStyle || {};
                    this.list.push(obj);
                }
                
            })
        },
        async createImageItem(image, className) {
            const goods = new GoodsElement()
            await goods.init(image, {
                maxWidth: 200,
                maxHeight: 200,
                class: className,
            })
            return goods
        },
    },
    computed: {},
}

</script>

<style lang="less" scoped>
.deck {
    position: relative;
    display: block;
    height: 648px;
}

.container {
    width: 600px;
    height: 600px;
    background-color: transparent;
    padding: 0;
    line-height: 0;
    position: absolute;
    /*margin: 30px auto;*/
    left: 0;
    top: 0;
    overflow: hidden;
}

.image-template {
    width: 600px;
    height: 600px;
    position: absolute;
    left: 0;
    top: 0;
    background-size: contain;
    opacity: 0.2;
}

.move-box {
    position: absolute;
    background-size: 100% auto !important;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
    user-select: none;
    opacity: 0;
    
    .img-delete {
        background-color: #333333;
    }
    
    .img-scale:after {
        border-bottom-color: #333333;
    }
}

.move-box.has-border {
    opacity: 1;
    border: 1px solid #333333;
    padding: 0;
}

.img-scale {
    position: absolute;
    width: 15px;
    height: 15px;
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
}

.img-delete {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    left: 0;
    cursor: pointer;
    background-color: #dddddd;
    color: white;
    line-height: 12px;
    text-align: center;
    
    .iconfont {
        font-size: 12px;
        color: white;
    }
}

.img-scale:after {
    content: ' ';
    width: 0;
    height: 0;
    border-bottom: 15px solid #ddd;
    border-left: 15px dashed transparent;
    position: absolute;
    right: 0;
    bottom: 0;
}

.hidden-color .move-box {
    border-color: transparent;
}

.hidden-color .img-scale:after {
    border-color: transparent;
}

.preview {
    border: 0;
}

.image-box {
    overflow: hidden;
    position: absolute;
    user-select: none;
}

.image-box .mask {
    display: flex;
    width: 100%;
    height: 100%;
    align-content: center;
    justify-content: space-around;
}

.image-box img {
    max-width: 100%;
    max-height: 100%;
}

.deck-tools {
    height: 48px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    
    .tool-item {
        height: 48px;
        display: flex;
        align-items: center;
        margin-right: 16px;
        cursor: pointer;
        
        img {
            width: 16px;
            height: 16px;
            margin-right: 8px;
        }
        
        span {
            color: #333333;
            font-size: 14px;
        }
    }
}

.tool-btn {
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    border: none;
    cursor: pointer;
    background-color: transparent;
    
    span {
        font-size: 12px;
        margin-right: 8px;
    }
    
    img {
        line-height: 0;
        vertical-align: middle;
        margin-right: 8px;
        width: 14px;
    }
}

.preview-btn,
.template-btn {
    width: 32px;
    height: 32px;
    background-color: white;
    border: 1px solid #EEEEEE;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 101;
    font-size: 12px;
}

.template-btn {
    top: 64px;
    
    img {
        width: 12px;
    }
}

.preview-modal {
    z-index: 102;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preview-bg {
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.preview-img {
    width: 600px;
    height: 600px;
    background-color: #F5F5F5;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    img {
        width: 600px;
        height: 600px;
    }
    
    .loading-gif {
        width: 48px;
        height: 48px;
    }
}
</style>